<template>
	<v-card flat height="100%" class="wr_grey_1">
		<Pad :pad_id="$route.params.pad_id"></Pad>
	</v-card>
</template>

<script>
import Pad from "@/components/shared/tools/etherpad/Pad.vue";

export default {
	name: "MentorEditorExplorePage",

	components: {
		Pad,
	},
};
</script>
